import React, {FC, useEffect, useState} from "react";
import {MapContainer, Polyline, Popup, TileLayer} from 'react-leaflet'
import {Box, Grid, Stack, Typography} from "@mui/material";
import "leaflet/dist/leaflet.css";
import {Flyby, Trace} from "types";
import {useAuthDispatch} from "./AuthContext";
import dayjs from "dayjs";


const MapView: FC = () => {

    const [blinkStatus, setBlinkStatus] = useState<"success" | "error">(null);
    const [updated, setUpdated] = useState(true)
    const [traces, setTraces] = useState<Trace[]>();

    const authDispatch = useAuthDispatch();

    // useEffect(() => {
    //     setBlinkStatus(null)
    //     fetch(`/api/trace`, {
    //         credentials: 'include',
    //     })
    //         .then(res => {
    //             if (res.ok) {
    //                 return res.json()
    //             } else {
    //                 authDispatch({type: 'fail'})
    //             }
    //         })
    //         .then(data => {
    //             setTraces(data);
    //             setBlinkStatus("success")
    //             setUpdated(true)
    //             setTimeout(() => setBlinkStatus(null), 300)
    //         })
    //         .catch(reason => {
    //             console.log(`api error: ${reason}`)
    //             setBlinkStatus("error")
    //             setUpdated(false)
    //             setTimeout(() => setBlinkStatus(null), 300)
    //         })
    //     const timer = setInterval(() => {
    //         setBlinkStatus(null)
    //         fetch(`/api/trace`)
    //             .then(res => {
    //                 if (res.ok) {
    //                     return res.json()
    //                 }
    //             })
    //             .then(data => {
    //                 setTraces(data)
    //                 setBlinkStatus("success")
    //                 setUpdated(true)
    //                 setTimeout(() => setBlinkStatus(null), 300)
    //             })
    //             .catch(reason => {
    //                 console.log(`api error: ${reason}`)
    //                 setBlinkStatus("error")
    //                 setUpdated(false)
    //                 setTimeout(() => setBlinkStatus(null), 300)
    //             })
    //     }, 10000)
    //     return () => {
    //         clearInterval(timer)
    //     }
    // }, [])

    useEffect(() => {
    }, [traces]);

    const formatDates = (trace: Trace) => {
        const dateFrom = dayjs(trace.dateTimeFrom)
        const dateTo = dayjs(trace.dateTimeTo)
        if (trace.points.length === 1) {
            return dateFrom.format('HH:mm');
        }
        const diffHours = dateTo.diff(dateFrom, 'hour')
        const diffMinutes = dateTo.diff(dateFrom, 'minute') % 60
        const diffSeconds = dateTo.diff(dateFrom, 'second') % 60
        const diff = `${diffHours.toString().padStart(2, "0")}:${diffMinutes.toString().padStart(2, "0")}:${diffSeconds.toString().padStart(2, "0")}`
        return `${dateFrom.format('HH:mm')} (${diff})`
    }

    return (
        <Box height={1}>
            <Stack borderBottom={1} p={1}
                   borderColor={'divider'} spacing={4}
                   flexDirection={"row"}
                   alignItems={'baseline'}>
                <Typography variant={"h6"}>Обстановка</Typography>
            </Stack>
            <Grid container height={0.9}>
                <Grid item xs={12} height={1} overflow={'hidden'}>
                    <MapContainer center={[48.688071, 38.022725]} zoom={13}
                                  style={{height: 600}}>
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {traces && traces.map(trace =>
                            <Polyline key={trace.id} pathOptions={{color: 'green', lineJoin: 'round'}} positions={trace.points}>
                                <Popup>{trace.type} {trace.freqs.join(',')}<br/>{formatDates(trace)}</Popup>
                            </Polyline>)
                        }
                    </MapContainer>
                </Grid>
            </Grid>
        </Box>
    )
}

export default MapView
